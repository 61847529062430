.sidemenu-wrapper {
	&.fixed-left {
		position: fixed;
		top: 0;
		left: 0;
		overflow: auto;
		height: 100%;
		height: 100vh;
		//added to keep it max-height size
		max-height: 100vh;
		background-color: $-color-charcoal !important;
		z-index: $z-index-side-menu;
		transition: transform 0.45s ease;

		@include media-breakpoint-down($-app-responsive-tablet-size) {
			-webkit-transform: translate3d(-100%, 0, 0);
			-ms-transform: translate3d(-100%, 0, 0);
			-o-transform: translate3d(-100%, 0, 0);
			transform: translate3d(-100%, 0, 0);
		}

		&.nav-open {
			-webkit-transform: translate3d(0, 0, 0);
			-ms-transform: translate3d(0, 0, 0);
			-o-transform: translate3d(0, 0, 0);
			transform: translate3d(0, 0, 0);
		}
	}
}

.sidemenu {
	width: $-app-sidebar-width;
	background-color: $-color-charcoal !important;
	flex-wrap: wrap;
	overflow: auto;
	position: relative;
	padding-top: 30px;

	img {
		max-width: 100%;
	}

	.navbar-brand {
		max-width: 100%;
		padding: 10px 25px;

		img {
			width: 140px;
			margin-left: 15px;

			@include media-breakpoint-down($-app-responsive-tablet-size) {
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.side-nav {
		background-color: $-color-charcoal;
		border-top: 0px;
		padding: 0 25px;
		width: 100%;
		margin-top: 1rem;
		box-sizing: border-box;
		position: relative;
		max-height: 75vh;
		overflow-y: auto;

		&:not(.at-bottom) {
			padding-bottom: 60px;

			@include media-breakpoint-down($-app-responsive-tablet-size) {
				padding-bottom: 20px;
			}
		}

		&.at-bottom {
			position: fixed;
			bottom: 0;
			width: $-app-sidebar-width;
			border-top: 1px solid rgba($-color-primary-ash, 0.3);
			padding-right: 6px;
			padding-left: 10px;
			padding-top: 8px;
			padding-bottom: 10px;
			z-index: 9999;

			// @include media-breakpoint-down($-app-responsive-tablet-size) {
			// 	position: relative;
			// }
		}
	}

	&.navbar-expand-lg .side-nav {
		flex-direction: column;
	}

	.nav-link {
		--bs-navbar-nav-link-padding-x: 15px;
		--bs-nav-link-padding-y: 10px;
		--bs-nav-link-color: white;

		position: relative;
		font-size: inherit;
		margin-bottom: 8px;
		transition: all 0.4s;
		border-radius: 4px;
		display: block;

		text-decoration: none;
		&:hover,
		&:focus,
		&:active {
			text-decoration: none;
		}

		&::before {
			position: absolute;
			content: ' ';
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			z-index: 24;
			cursor: pointer;
		}

		i {
			line-height: 1;
			margin-right: 10px;
			position: absolute;
			left: 15px;
			margin-top: -3px;
			z-index: 12;
		}

		&.no-icon {
			padding-left: 15px !important;
		}

		&.has-collapse {
			&.active {
				color: $-color-sand;
			}

			&:hover {
				color: $-color-primary-green;
			}
		}

		&.active,
		&:hover {
			background-color: white;
			color: $-color-primary-green;
		}

		&.nav-link-show {
			&:not(:hover) {
				background-color: rgba(255, 255, 255, 0.1);
			}

			&:after {
				transform: rotate(180deg);
				transform-origin: 50%;
				top: 10%;
			}
		}

		&:not(.nav-link-show) {
			&.active {
				color: $-color-primary-green;
			}
		}
	}

	ul.nav-second-level {
		padding-left: 0;
		margin-bottom: 0;
		padding-left: 20px;
	}

	.dropdown-menu {
		position: relative !important;
		top: 0;
	}
}

/** For container menu - for menu that is not fixed */
.relative-navigation {
	.side-nav {
		&.at-bottom {
			position: fixed;
			bottom: 0;
			width: $-app-sidebar-width;
			margin-top: 10%;
		}
	}
}

#user-accounts-active {
	.white-icon {
		// filter: invert(1);
	}

	.arrow {
		> * {
			transition: 0.15s all ease-in-out;
		}

		.open {
			transform: rotate(180deg);
		}
	}

	.option-select {
		max-height: 400px;
		overflow-y: auto;
	}

	.ellipses {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		max-width: 105%;
	}

	.border-bottom {
		border-color: #ccc !important;
	}

	.form {
		&-check {
			padding-right: 2rem;

			.form-check-input {
				float: right;
				margin-right: -2rem;
				background-color: #fff;

				&:checked {
					background-color: $-primary-color;
					border-color: $-primary-color;

					&[type='radio'] {
						background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
					}
				}

				&:disabled + label {
					opacity: 0.5;
				}
			}

			label {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}

	.form-input-wrapper {
		cursor: pointer;
		&:hover {
			background: #f8f9fa; // $gray-100

			// Apply pointer cursor if the input is not disabled
			input:not([disabled]) {
				&,
				& + label {
					cursor: pointer;
				}
			}
		}
	}

	.account-switcher-handle {
		position: relative;
		&::before {
			position: absolute;
			display: block;
			content: ' ';
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
		}

		.names {
			max-width: 90%;
		}
	}
}

/** Responsive */
/** top menu */
.topmenu-wrapper {
	z-index: $z-index-top-menu;
	height: 0px;
	overflow: hidden;
	opacity: 0;
	visibility: hidden;
	transition: all 0.35s ease;

	@include media-breakpoint-down($-app-responsive-tablet-size) {
		height: $-app-topbar-height;
		opacity: 1;
		visibility: visible;
	}

	.navbar-brand {
		img {
			width: 100%;
			max-width: 120px;
			position: relative;
			margin-left: $-app-content-padding;
		}
	}

	.navbar-toggler {
		display: block;
		&::after {
			content: ' ';
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			left: 0;
			top: 0;
		}

		&,
		&:hover,
		&:focus,
		&:active {
			border: 0;
			box-shadow: none;
		}

		&.menu-toggle {
			position: absolute;
			right: $-app-content-padding;
			top: 49%;
			transform: translate(0, -50%);
			height: 26px;
			width: 29px;

			&,
			&:hover {
				color: #000;
			}
		}

		.menu-toggle-bar {
			display: block;
			position: absolute;
			top: 50%;
			margin-top: -1px;
			right: 0;
			width: 100%;
			height: 2px;
			border-radius: 4px;
			background-color: #fff;
			transition: all 0.3s ease;

			&.menu-toggle-bar--top {
				transform: translate(0, -8px);
			}
			&.menu-toggle-bar--middle {
			}
			&.menu-toggle-bar--bottom {
				transform: translate(0, 8px);
			}
		}

		&.nav-open {
			.menu-toggle-bar {
				&.menu-toggle-bar--top {
					transform: translate(0, 0) rotate(45deg);
				}
				&.menu-toggle-bar--middle {
					opacity: 0;
				}
				&.menu-toggle-bar--bottom {
					transform: translate(0, 0) rotate(-45deg);
				}
			}
		}
	}
}
