@import 'bootstrap/scss/functions.scss';
@import 'bootstrap/scss/variables.scss';
@import 'bootstrap/scss/mixins.scss';
@import './functions.scss';
@import './variables.scss';
@import './fonts.scss';

@import 'react-datepicker/dist/react-datepicker.css';
@import 'rsuite/dist/rsuite-no-reset.css';

// @import 'rsuite.scss';

body {
	margin: 0;
	--bs-body-font-size: #{$-font-size};
	--bs-body-font-family: #{$-font-body}, 'Roboto', 'Helvetica Neue', sans-serif;
	--bs-body-font-weight: 400;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1.2;
}

img {
	display: inline;
}

b,
h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
}

b,
strong {
	font-weight: 800;
}

h1 {
	font-family: $-font-medium, 'Helvetica Neue', sans-serif;
	font-size: 3.125rem;
	/* 50px */
}

h2,
h3 {
	font-family: $-font-bold, 'Helvetica Neue', sans-serif;
}

h2 {
	font-size: 2rem; /* 42px */
}

h3 {
	font-size: 1.75rem;
	/* 28px */
}

h4 {
	font-size: 1.5rem;
	/* 24px */
}

h5 {
	font-size: 1.25rem;
	/* 20px */
}

h6 {
	font-size: 1rem;
	/* 16px */
}

h4,
h5,
h6,
p {
	font-family: $-font-body;
}

h1.project {
	font-size: 1.5rem;
}

h2.project {
	font-size: 1.1rem;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

a {
	text-underline-offset: 2px;
}

.no-outline {
	border: 0;
	box-shadow: none;
	outline: none;

	&-active {
		&:active,
		&:hover,
		&:focus {
			border: 0 !important;
			box-shadow: none !important;
			outline: none !important;
		}
	}
}

/**********************************
	Main Contents
 *********************************/
.main-content {
	padding-left: $-app-sidebar-width;
	transition: padding 0.35s ease;

	@include media-breakpoint-down($-app-responsive-tablet-size) {
		padding-left: 0px;
		padding-top: $-app-topbar-height-mobile;
	}

	.content-padding {
		padding: 30px $-app-content-padding;
	}

	.has-action-bar {
		padding-bottom: 120px;
	}

	.min-height {
		min-height: calc(100vh - 360px);

		&.mh-270 {
			min-height: calc(100vh - 270px);
		}
	}
}

.impersonation-banner {
	height: 35px;
	z-index: 1020;
	background-color: #2596be;
	z-index: 99999;
}

.nav-container {
	position: fixed;
	z-index: 99999;
	display: flex;
	flex-direction: column;
	width: 100%;
}

.app-contains-action-bar {
	.content-padding {
		padding-bottom: 120px;
	}
}

/** FONTS *****/

.ff-type-medium {
	font-family: $-font-medium, 'Helvetica Neue', sans-serif !important;
}

.ff-type-bold {
	font-family: $-font-bold, 'Helvetica Neue', sans-serif !important;
}

.ff-type-body {
	font-family: $-font-body, 'Roboto', 'Helvetica Neue', sans-serif;
}

@for $i from 1 through 46 {
	@if $i % 2==0 {
		.fsx-#{$i} {
			font-size: #{$i}px !important;
		}
	}
}

@each $name, $px in $-font-sizes {
	.fs-#{$name} {
		font-size: $px !important;
	}
}

@each $size in $-font-weight {
	.fw-#{$size} {
		font-weight: $size;
	}
}

/**********************************
	SCROLLBAR
 *********************************/
* {
	scrollbar-color: #8a8a8a #d2d2d2;
	scrollbar-width: thin !important;
}

*::-webkit-scrollbar-thumb {
	border-radius: 10px;
}

*::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

*::-webkit-scrollbar-track {
	border-radius: 10px;
}

*::-webkit-scrollbar-track-piece {
	background-color: #d2d2d2;
	background-color: transparent;
}

*::-webkit-scrollbar-thumb:vertical {
	height: 8px;
	background-color: #8a8a8a;
	background-color: transparent;
	border-radius: 10px;
}

*::-webkit-scrollbar-thumb:horizontal {
	background-color: #8a8a8a;
	background-color: transparent;
	border-radius: 10px;
}

/* body */
body::-webkit-scrollbar-thumb:vertical {
	background-color: #8a8a8a;
}

body::-webkit-scrollbar-thumb:horizontal {
	background-color: #8a8a8a;
}

body::-webkit-scrollbar-track-piece {
	background-color: #d2d2d2;
}

*:hover {
	&::-webkit-scrollbar-thumb:vertical {
		background-color: #8a8a8a;
	}

	&::-webkit-scrollbar-thumb:horizontal {
		background-color: #8a8a8a;
	}
}

/**********************************
	Components
 *********************************/
@import './uikit/sidebar-menu.scss';
@import './uikit/switch.scss';
@import './uikit/progress-circle.scss';
@import './uikit/box.scss';
@import './uikit/headers.scss';
@import './uikit/action-bar.scss';
@import './uikit/input-bar.scss';
@import './uikit/table.scss';
@import './uikit/modal.scss';
@import './uikit/clipper.scss';
@import './uikit/treeview.scss';
@import './uikit/wysiwyg.scss';
@import './uikit/dropzone.scss';
@import './uikit/list-filter.scss';
@import './uikit/floating-help.scss';
@import './uikit/mini-zoomer.scss';
@import './uikit/footer.scss';
@import './uikit/datepicker.scss';
@import './uikit/pagination.scss';
@import './uikit/combobox.scss';
@import './uikit/documents-grid.scss';
@import './uikit/stock-items.scss';
@import './uikit/gantt.scss';

/***************************************************************
	Override Bootstrap, Layout, React Select, etc.
 **************************************************************/
@import './uikit/override.scss';
@import './uikit/calendar.scss';
@import './uikit/pages.scss';

/***************************************************************
	RESPONSIVE
 **************************************************************/
@import './app-branding-responsive.scss';

/***************************************************************
	Login Page
 **************************************************************/

.loginContainer {
	min-height: 100vh;

	.logo {
		max-width: 120px;
		z-index: 2;
		position: relative;
	}

	.loginLeft {
		background-image: url('../images/auth-interior-designers.jpg');
		background-position: center;
		background-size: cover;
		position: relative;

		&:before {
			content: '';
			background: rgba(#000, 0.6);
			position: absolute;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
			z-index: 0;
		}

		&-footer {
			text-align: center;
			color: #fff;
			position: relative;
			z-index: 2;
			font-size: 12px;

			.logos {
				img {
					max-width: 120px;
					max-height: 80px;
					margin: 0 8px;

					&:last-of-type {
						max-width: 80px;
						max-height: 80px;
					}
				}
			}
		}
	}

	.loginRight {
		&-form {
			display: flex;
			align-items: center;
			justify-content: center;

			& > div {
				width: 100%;
				max-width: 550px;
			}
		}

		a {
			color: $-darkgrey-color;
			font-weight: normal;
			text-decoration: none;

			&:hover {
				color: $-dark-color;
			}
		}

		&-footer {
			text-align: center;
			color: $-darkgrey-color;
			font-size: 12px;

			a {
				color: $-primary-color;
				text-decoration: underline;
			}
		}
	}
}

#response-alert-div,
#response-alert-div-main {
	position: fixed;
	right: 0;
	bottom: 0;
	z-index: 9999999999999;
	padding-left: 40px;

	&::before {
		background: transparent;
		transition: background-color 0.3s ease-in-out;
	}

	&.has-backdrop {
		&::before {
			content: ' ';
			position: fixed;
			top: 0;
			left: 232px;
			width: calc(100vw - 232px);
			height: 100%;
			background-color: rgba(255, 255, 255, 0.4);

			@include media-breakpoint-down(xl) {
				left: 0px;
				top: 65px;
				height: calc(100vh - 65px);
				width: 100%;
			}
		}
	}
}

#response-alert {
	position: relative;
	border: 1px solid #88ba88;
	border-left-color: #178344;
	border-left-width: 6px;
	font-size: 18px;
	padding-bottom: 22px;
	padding-top: 22px;
	padding-left: 26px;
	padding-right: 26px;
	font-weight: 600;
	min-width: 280px;

	&:not(.alert-body) {
		z-index: 999999;
		max-width: 900px;
		bottom: 10px;
		right: 20px;
		box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.55);
	}

	&.alert-body {
		box-shadow: 0 1px 4px 1px hsla(0, 0%, 0%, 0.065),
			0 4px 8px hsla(0, 0%, 0%, 0.065);
	}

	&.alert-danger {
		border-color: #d2a5a5;
		border-left-color: #8f130c;
	}

	&.alert-warning {
		border-color: #dac171;
		border-left-color: #8a5700;
	}

	&.alert-primary {
		color: white;
		border-color: #057805;
		border-left-color: #075507;
	}

	&.alert-info {
		border-color: #67c7da;
		border-left-color: #026c81;
	}

	&.alert-white {
		border-color: #b9b9b9;
		border-left-color: #555;
	}

	.alert-icon {
		position: relative;
		top: 0px;
		font-size: 20px;
		margin-right: 8px;

		&.spinner-border {
			top: 4px;
		}
	}

	.close {
		position: absolute;
		width: 24px;
		height: 24px;
		opacity: 0.7;
		border-width: 0px;
		border-style: solid;
		border-radius: 50%;
		right: 0;
		top: 0;
		text-align: center;
		font-size: 24px;
		line-height: 1;
		cursor: pointer;
		text-decoration: none;
		color: inherit;

		&:hover {
			opacity: 1;
		}
	}

	&.has-icon {
		display: flex;
	}

	&.compact {
		font-size: 14px;
		padding-bottom: 15px;
		padding-top: 15px;
		padding-left: 20px;
		padding-right: 20px;
		border-left-width: 5px;

		.alert-icon {
			font-size: 16px;
		}
	}
}

#page-loader {
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999999999;
	transition: opacity 0.17s ease-out, visibility 0.17s ease-out;
	background-color: rgb(255, 255, 255, 0.15);
	backdrop-filter: blur(1.5px);
	-webkit-backdrop-filter: blur(1.5px);

	> div {
		z-index: 14;
		position: relative;
	}

	&.has-sidebar {
		width: calc(100% - 240px);
		margin-left: 240px;
	}

	&.fadeout {
		opacity: 0;
		visibility: hidden;
	}

	&.no-opacity {
		background-color: #ffffff;
	}
}

.taxCalculationsTable {
	& > div {
		padding-left: 30px;
		padding-bottom: 14px;
	}
}

.rdw-colorpicker-modal-style-label {
	display: none;
}

.page-error {
	display: table-cell;
	vertical-align: middle;
	--typeto: 65%;

	&__heading {
		font-size: 180px;
		display: inline-block;
		padding-right: 12px;
		overflow: hidden;
		animation: typing 2.5s steps(50, end), error-type 0.5s alternate infinite;

		@include media-breakpoint-down($-app-responsive-mobile-size) {
			--typeto: 50%;
			font-size: 140px;
		}

		@include media-breakpoint-down(sm) {
			--typeto: 45%;
			font-size: 100px;
		}
	}
}

/* The typing effect */
@keyframes typing {
	from {
		width: 0;
	}
	to {
		width: var(--typeto);
	}
}

@keyframes error-type {
	from {
		box-shadow: inset -5px 0px 0px #000;
	}
	to {
		box-shadow: inset -5px 0px 0px transparent;
	}
}

.dot-load {
	width: 8px;
	margin-left: 1px;
}

.dot-load:after {
	overflow: hidden;
	display: inline-block;
	vertical-align: bottom;
	-webkit-animation: ellipsis steps(4, end) 1200ms infinite;
	animation: ellipsis steps(4, end) 1200ms infinite;
	content: '\2026';
	width: 0px;
}

@keyframes ellipsis {
	to {
		width: 18px;
	}
}

@-webkit-keyframes ellipsis {
	to {
		width: 18px;
	}
}

.static-field-rows {
	max-width: 900px;
	@include media-breakpoint-down($-app-responsive-mobile-size) {
		max-width: 100%;
	}
}
